<template>
  <v-app>
    <div>
      <!--begin::Dashboard-->
      <div class="row">
        <div class="col-xl-12">
          <h1>
            Nivel de electrificación. {{ this.currentObject.nombre }} -
            {{ this.currentProvince.nombre }}
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              <v-select
                :items="this.currentObject.provincias"
                item-text="nombre"
                item-value="slug"
                v-model="selected"
                v-on:change="changeProvincia"
              ></v-select>

              <v-spacer></v-spacer>

              <router-link
                :to="'/vehiculo-electrico/' + this.$route.params.comunidad"
                class="btn btn-success font-weight-bolder font-size-sm"
                >Volver a {{ this.currentObject.nombre }}</router-link
              >
            </v-card-title>
          </v-card>
        </div>
        <div class="col-xl-8">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title
              >Contribución a la descarbonización del Vehículo
              Eléctrico</v-card-title
            >
            <v-card-text class="d-flex align-items-center flex-wrap">
              <h2>Nacional</h2>
              <h2 style="color: green">1000</h2>

              <v-spacer></v-spacer>

              <h2>Comunidad</h2>
              <h2 style="color: green">{{ this.currentObject.VE }}</h2>
              <v-spacer></v-spacer>
              <h2>Provincia</h2>
              <h2 style="color: green">{{ this.currentProvince.VE }}</h2>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>Número de vehículos eléctrcios</v-card-title>
            <v-card-subtitle>(Por tipología de vehiculo)</v-card-subtitle>
            <v-card-text>
              <fusioncharts
                type="msbar2d"
                width="100%"
                height="400"
                dataFormat="jsonurl"
                :dataSource="
                  '/data/VE/provincia/' +
                  this.currentSlug +
                  '_tipovehiculo_inp02.json'
                "
              >
              </fusioncharts>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <!--end::Dashboard-->
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      selected: this.$route.params.provincia,
      provincias: [
        "Almería",
        "Cádiz",
        "Córdoba",
        "Granada",
        "Huelva",
        "Jaén",
        "Málaga",
        "Sevilla",
      ],
    };
  },
  computed: {
    currentSlug() {
      return this.$route.params.provincia;
    },
    currentObject() {
      let selectedRegion = this.$regions.filter((comunidad) => {
        return comunidad.slug == this.$route.params.comunidad;
      });
      return selectedRegion[0];
    },
    currentProvince() {
      let selectedProvince = this.currentObject.provincias.filter(
        (provincia) => {
          return provincia.slug == this.$route.params.provincia;
        }
      );
      return selectedProvince[0];
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    changeProvincia(selected) {
      this.$router.push({
        name: "vehiculo-electrico-provincia",
        params: {
          comunidad: this.$route.params.comunidad,
          provincia: selected,
        },
      });
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
